import styled from 'styled-components'
import { mq } from '../../styles/vars/media-queries.style'
import { Heading3 } from '../../styles/vars/textStyles.style'

export const CarouselSection = styled.div`
  padding-top: ${props => (props.title ? '4rem' : '7.4rem')};

  ${Heading3} {
    margin-bottom: 10.4rem;
  }

  ${mq.tabletL} {
    padding-top: ${props => (props.title ? '14rem' : '26rem')};

    ${Heading3} {
      margin-bottom: 19.5rem;
    }
  }
`
