import styled from 'styled-components'
import { colors } from '../../styles/vars/colors.style'
import { easings } from '../../styles/vars/easings.style'
import { font } from '../../styles/vars/font.style'
import { mq } from '../../styles/vars/media-queries.style'
import { ContainerMain } from '../Container/index.style'
import { RichTextMain } from '../RichText/index.style'
import { TextBody, TextBodyBold } from '../../styles/vars/textStyles.style'
import { setVh } from '../../styles/utils/conversion.style'

export const SectionDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${setVh('height', 100, true)}
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  transition: opacity 1s ${easings.modal.default};
  transition-delay: ${props => (props.modalOpen ? '0s' : '0.5s')};
  z-index: 15;
  opacity: ${props => (props.modalOpen ? 1 : 0)};
  pointer-events: ${props => (props.modalOpen ? undefined : 'none')};

  ${ContainerMain} {
    opacity: ${props => (props.modalOpen ? 1 : 0)};
  }
`

export const PortalDiv = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
`

export const Wrap = styled.div`
  transition: opacity 0.5s ${easings.modal.default};

  ${({ modalOpen }) => {
    return modalOpen === false
      ? `opacity: 0;
  pointer-events: none;
  transition-delay: .5s;`
      : `opacity: 1;
  pointer-events: all;
  transition-delay: 0.5s;`
  }}
`
export const ModalInner = styled.div`
  background: ${colors.light};
  border-radius: 1rem;
  bottom: auto;
  ${setVh('max-height', 90, true)}
  left: auto;
  overflow: scroll;
  padding: 4.5rem 3.9rem 0;
  position: relative;
  right: auto;
  top: auto;

  ${mq.tabletL} {
    bottom: auto;
    left: auto;
    padding: 8rem calc((100% / 16) * 2) 0;
    position: relative;
    right: auto;
    top: auto;
  }

  ${mq.desk} {
    margin-left: calc((100vw / 16) * 0.5);
    margin-right: calc((100vw / 16) * 0.5);
    padding: 8rem calc(100% / 16) 0;
  }

  h2 {
    padding-bottom: 4rem;
    position: relative;

    &:after {
      background-color: ${colors.dark};
      bottom: 0;
      content: '';
      height: 0.1rem;
      left: 0;
      opacity: 0.12;
      position: absolute;
      right: 0;
      bottom: 0;

      ${mq.tabletL} {
        left: calc((100% / -16) * 4);
        right: calc((100% / -16) * 4);
      }
    }
  }

  p {
    &:not(:last-of-type) {
      margin-bottom: 0rem;
    }
    &:last-of-type {
      margin-bottom: 0rem;
    }
  }

  li {
    ${TextBodyBold},
    ${TextBody} {
      margin-bottom: 0;
    }
  }

  ${RichTextMain} {
    padding-bottom: 8rem;

    ${mq.tabletL} {
      padding-bottom: 14rem;
    }

    ${mq.desk} {
      padding-bottom: 14rem;
    }
  }
`

export const ModalTop = styled.div`
  align-content: center;
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  padding-bottom: 6rem;
`

export const ModalTopText = styled.div`
  display: inline-block;
  flex: 1;
  margin-left: 2.1rem;

  ${mq.desk} {
    margin-left: 3rem;
  }
`

export const ModalGradient = styled.div`
  position: absolute;
  right: 2.2rem;
  bottom: 0;
  left: 2.2rem;
  height: 8rem;
  background: linear-gradient(rgba(253, 245, 237, 0) 0%, ${colors.light} 50%);
  border-radius: 1rem;
  pointer-events: none;

  ${mq.tabletL} {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: 14rem;
  }

  ${mq.desk} {
    left: calc((100vw / 16) * 0.5);
    right: calc((100vw / 16) * 0.5);
    height: 14rem;
  }
`

export const CloseButton = styled.div`
  margin-bottom: 4.4rem;
  padding-bottom: 2.2rem;
  padding-top: 2rem;
  position: absolute;
  top: 0rem;
  right: 2.2rem;
  z-index: 2;
`
export const Close = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  display: block;
  font-family: ${font.secondary.family};
  font-size: 1.4rem;
  margin-left: auto;
  margin-right: auto;
  text-transform: uppercase;
  color: ${colors.dark};

  ${mq.tabletL} {
    margin-right: 0;
  }
`

export const ModalImageWrap = styled.div`
  border-radius: 50%;
  display: inline-block;
  height: 8.6rem;
  overflow: hidden;
  width: 8.6rem;

  ${mq.desk} {
    height: 10rem;
    width: 10rem;
  }
`

export const ModalImage = styled.img``
