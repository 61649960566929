import React from 'react'
import {
  Heading2,
  Heading3,
  TextBodyBold,
} from '../../styles/vars/textStyles.style'
import AnimateFadeIn from '../animation/AnimateFadeIn'

// Components
import Button from '../Button/index'
import Grid from '../Grid/index'
import GridItem from '../GridItem/index'
import InViewSection from '../InViewSection/index'
import RichText from '../RichText/index'
import ScrollSection from '../ScrollSection'

import { TextSection, ContentLeft, ContentRight } from './index.style'

const Text = ({ repeaterContent, start, button, bigHeading }) => {
  const sectionDelay = 0.5

  if (repeaterContent.title) {
    return (
      <ScrollSection>
        <TextSection single>
          <Grid>
            {start === 'right' || start === 'Right' ? (
              <GridItem
                mobile={16}
                tabletL={6}
                tabletLStart={9}
                desk={bigHeading ? 5 : 6}
                deskStart={bigHeading ? 10 : 9}
                deskL={bigHeading ? 4 : 5}
                deskLStart={bigHeading ? 10 : 9}
              >
                <ContentRight>
                  <AnimateFadeIn delay={sectionDelay}>
                    {bigHeading ? (
                      <Heading2>{repeaterContent.title}</Heading2>
                    ) : (
                      <Heading3 as={`h2`}>{repeaterContent.title}</Heading3>
                    )}
                    {repeaterContent.copy.raw ? (
                      <RichText content={repeaterContent.copy} small />
                    ) : (
                      <TextBodyBold>{repeaterContent.copy}</TextBodyBold>
                    )}
                    {button ? (
                      <Button
                        to={button.buttonLink}
                        text={button.buttonText}
                        hasborder
                        theme={4}
                      />
                    ) : (
                      ``
                    )}
                  </AnimateFadeIn>
                </ContentRight>
              </GridItem>
            ) : (
              <GridItem
                mobile={16}
                tabletL={6}
                tabletLStart={3}
                desk={bigHeading ? 5 : 6}
                deskStart={3}
                deskL={bigHeading ? 4 : 5}
                deskLStart={3}
              >
                <ContentLeft>
                  <AnimateFadeIn delay={sectionDelay}>
                    {bigHeading ? (
                      <Heading2>{repeaterContent.title}</Heading2>
                    ) : (
                      <Heading3 as={`h2`}>{repeaterContent.title}</Heading3>
                    )}
                    {repeaterContent.copy.raw ? (
                      <RichText content={repeaterContent.copy} small />
                    ) : (
                      <TextBodyBold>{repeaterContent.copy}</TextBodyBold>
                    )}
                    {button ? (
                      <Button
                        to={button.buttonLink}
                        text={button.buttonText}
                        hasborder
                        theme={4}
                      />
                    ) : (
                      ``
                    )}
                  </AnimateFadeIn>
                </ContentLeft>
              </GridItem>
            )}
          </Grid>
        </TextSection>
      </ScrollSection>
    )
  } else {
    return (
      <ScrollSection delay={sectionDelay} trackInView={false}>
        <TextSection>
          {repeaterContent.map((section, key) => {
            if (start === 'right' || start === 'Right') {
              if (key % 2 === 0) {
                return (
                  <Grid key={key}>
                    <GridItem mobile={16} tabletL={6} tabletLStart={9}>
                      <ContentRight>
                        <InViewSection>
                          <AnimateFadeIn delay={sectionDelay}>
                            <Heading3 as={`h2`}>{section.title}</Heading3>
                            {section.copy.raw ? (
                              <RichText content={section.copy} small />
                            ) : (
                              <TextBodyBold>{section.copy}</TextBodyBold>
                            )}
                          </AnimateFadeIn>
                        </InViewSection>
                      </ContentRight>
                    </GridItem>
                  </Grid>
                )
              } else {
                return (
                  <Grid key={key}>
                    <GridItem mobile={16} tabletL={6} tabletLStart={3}>
                      <ContentLeft>
                        <InViewSection>
                          <AnimateFadeIn delay={sectionDelay}>
                            <Heading3 as={`h2`}>{section.title}</Heading3>
                            {section.copy.raw ? (
                              <RichText content={section.copy} small />
                            ) : (
                              <TextBodyBold>{section.copy}</TextBodyBold>
                            )}
                          </AnimateFadeIn>
                        </InViewSection>
                      </ContentLeft>
                    </GridItem>
                  </Grid>
                )
              }
            } else {
              if (key % 2 === 0) {
                return (
                  <Grid key={key}>
                    <GridItem mobile={16} tabletL={6} tabletLStart={3}>
                      <ContentLeft>
                        <InViewSection>
                          <AnimateFadeIn delay={sectionDelay}>
                            <Heading3 as={`h2`}>{section.title}</Heading3>
                            {section.copy.raw ? (
                              <RichText content={section.copy} small />
                            ) : (
                              <TextBodyBold>{section.copy}</TextBodyBold>
                            )}
                          </AnimateFadeIn>
                        </InViewSection>
                      </ContentLeft>
                    </GridItem>
                  </Grid>
                )
              } else {
                return (
                  <Grid key={key}>
                    <GridItem mobile={16} tabletL={6} tabletLStart={9}>
                      <ContentRight>
                        <InViewSection>
                          <AnimateFadeIn delay={sectionDelay}>
                            <Heading3 as={`h2`}>{section.title}</Heading3>
                            {section.copy.raw ? (
                              <RichText content={section.copy} small />
                            ) : (
                              <TextBodyBold>{section.copy}</TextBodyBold>
                            )}
                          </AnimateFadeIn>
                        </InViewSection>
                      </ContentRight>
                    </GridItem>
                  </Grid>
                )
              }
            }
          })}
        </TextSection>
      </ScrollSection>
    )
  }
}

export default Text
