import React, { useEffect, useContext, useRef, useCallback } from 'react'

// Components
import Container from '../Container/index'
import Grid from '../Grid/index'
import GridItem from '../GridItem/index'
import RichText from '../RichText'
import Portal from '../Portal'
import { TextBody, TextBodyBold } from '../../styles/vars/textStyles.style'

import {
  PortalDiv,
  SectionDiv,
  ModalGradient,
  Close,
  CloseButton,
  ModalImageWrap,
  ModalImage,
  ModalTop,
  ModalTopText,
  Wrap,
  ModalInner,
} from './index.style'

// Context
import { StoreDispatch } from '../../Store'

const Section = ({
  modalOpen,
  modalTitle,
  modalContent,
  setModalOpen,
  modalName,
  modalImage,
}) => {
  const dispatch = useContext(StoreDispatch)
  const open = useRef(modalOpen)

  const closeModal = useCallback(() => {
    setModalOpen(false)
    dispatch({ type: 'CIRCLE_SHRINK' })
    dispatch({ type: 'HIDE_MODAL' })
    document.querySelector('body').classList.remove('disable-scroll')
  }, [dispatch, setModalOpen])

  useEffect(() => {
    open.current = modalOpen
  }, [modalOpen])

  useEffect(() => {
    const checkKey = e => {
      if (open.current) {
        if (e.key === 'Escape' || e.code === 'Escape') {
          closeModal()
        }
      }
    }

    window.addEventListener('keyup', checkKey)

    return () => {
      window.removeEventListener('keyup', checkKey)
    }
  }, [dispatch, setModalOpen, closeModal])

  return (
    <Portal>
      <PortalDiv>
        <SectionDiv
          modalOpen={modalOpen}
          onClick={() => {
            closeModal()
          }}
          aria-live="polite"
          role="dialog"
          aria-label="Team member bio"
          tabIndex={modalOpen ? 0 : -1}
        >
          <Container>
            <Grid>
              <GridItem
                mobile={16}
                tabletL={8}
                tabletLStart={5}
                deskL={6}
                deskLStart={6}
              >
                <Wrap modalOpen={modalOpen} onClick={e => e.stopPropagation()}>
                  <ModalInner>
                    <CloseButton>
                      <Close
                        onClick={() => {
                          closeModal()
                        }}
                      >
                        Close
                      </Close>
                    </CloseButton>

                    <ModalTop>
                      <ModalImageWrap>
                        <ModalImage
                          src={modalImage?.file.url}
                          alt=""
                          height={160}
                          width={160}
                        />
                      </ModalImageWrap>
                      <ModalTopText>
                        <TextBody>{modalName}</TextBody>
                        <TextBodyBold>{modalTitle}</TextBodyBold>
                      </ModalTopText>
                    </ModalTop>
                    {modalContent && <RichText content={modalContent} small />}
                  </ModalInner>

                  <ModalGradient modalOpen={modalOpen} />
                </Wrap>
              </GridItem>
            </Grid>
          </Container>
        </SectionDiv>
      </PortalDiv>
    </Portal>
  )
}

export default Section
