import styled from 'styled-components'
import { mq } from '../../styles/vars/media-queries.style'
import { TextBodyBold } from '../../styles/vars/textStyles.style'
import { Button } from '../Button/index.style'

export const Section = styled.div`
  margin-bottom: 14rem;

  ${mq.desk} {
    margin-bottom: 20rem;
  }
`

export const Wrap = styled.div`
  margin-bottom: 5.4rem;
  position: relative;

  ${mq.tabletL} {
    margin-bottom: 20rem;
  }
`
export const CarouselContainer = styled.div`
  margin-left: -4.4rem;
  margin-right: -4.4rem;
  overflow: hidden;
  padding-left: 4.4rem;

  ${mq.tabletL} {
    margin-left: -5%;
    margin-right: 0rem;
    padding-left: 5%;
  }

  ${mq.desk} {
    /* margin-left: -8rem; */
    /* padding-left: 8rem; */
  }
`

// export const CarouselGradient = styled.div`
//   background: linear-gradient(
//     to left,
//     rgba(253, 245, 237, 0) 0%,
//     ${colors.light} 71.12%
//   );
//   left: -5%;
//   pointer-events: none;
//   position: absolute;
//   width: 5%;
//   top: 0;
//   bottom: 0;
//   z-index: 10009;
//   display: none;
//   opacity: 1;
//   transition: opacity 0.5s ${easings.modal.default};

//   ${mq.tabletL} {
//     display: block;
//   }

//   ${mq.desk} {
//   }
// `

export const CarouselWrapper = styled.div``

export const CarouselInner = styled.div`
  display: flex;
  width: fit-content;
  white-space: nowrap;
  cursor: none !important;
`

export const CarouselItem = styled.div`
  position: relative;
  display: inline-block;
  margin-right: 2.2rem;
  width: calc((100vw / 16) * 10);
  white-space: normal;

  ${mq.tabletP} {
    width: calc((100vw / 16) * 6);
  }

  ${mq.tabletL} {
    width: calc((100vw / 16) * 2.8);
  }

  ${TextBodyBold} {
    margin-top: 1.2rem;
  }

  ${Button} {
    padding: 0;

    button {
      position: static;
      border-radius: 0;
      padding: 0;

      ${mq.desk} {
        position: relative;
      }

      :before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: block;
      }

      &:after {
        position: static;
        bottom: 0;
        left: 0;
        right: 0;
        display: block;
      }
    }

    span {
      line-height: 1.5;
    }
  }
`

export const CarouselImage = styled.img`
  border-radius: 1rem;
  margin-bottom: 3.8rem;
`
