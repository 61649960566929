import React, { useState, useEffect } from 'react'
import { Heading3 } from '../../styles/vars/textStyles.style'

// Components
import CarouselModal from '../CarouselModal/index'
import Container from '../Container/index'
import Carousel from '../Carousel/index'
import Grid from '../Grid/index'
import GridItem from '../GridItem/index'
import InViewSection from '../InViewSection/index'
import ScrollSection from '../ScrollSection'

import { CarouselSection } from './index.style'
import AnimateFadeIn from '../animation/AnimateFadeIn'

const Section = ({ title, teams }) => {
  const [modalTitle, setModalTitle] = useState(null),
    [modalName, setModalName] = useState(null),
    [modalContent, setModalContent] = useState(null),
    [modalImage, setModalImage] = useState(null),
    [modalOpen, setModalOpen] = useState(false)

  useEffect(() => {
    if (modalOpen) {
      if (window.innerWidth <= 1024) {
        // if (
        //   !document.querySelector('body').classList.contains('disable-scroll')
        // ) {
        document.querySelector('body').classList.add('disable-scroll')
        // }
      } else {
        if (window?.scroll?.stop) {
          window.scroll.stop()
        }
      }
    } else {
      if (window.innerWidth <= 1024) {
        if (
          document.querySelector('body').classList.contains('disable-scroll')
        ) {
          document.querySelector('body').classList.remove('disable-scroll')
        }
      } else {
        if (window?.scroll?.update) {
          window.scroll.update()
          window.scroll.start()
        }
      }
    }
  }, [modalOpen])

  return (
    <>
      <ScrollSection trackInView={false}>
        <CarouselSection title={title}>
          <Container>
            {title && (
              <Grid>
                <GridItem
                  mobile={16}
                  tabletL={5}
                  tabletLStart={2}
                  desk={5}
                  deskStart={3}
                >
                  <InViewSection>
                    <AnimateFadeIn>
                      <Heading3 as={`h2`}>{title}</Heading3>
                    </AnimateFadeIn>
                  </InViewSection>
                </GridItem>
              </Grid>
            )}

            {teams.map((singleCarousel, key) => {
              return (
                <Carousel
                  title={singleCarousel.carouselTitle}
                  content={singleCarousel.carouselCopy}
                  gallery={singleCarousel.carouselItems}
                  key={key}
                  setModalContent={setModalContent}
                  setModalName={setModalName}
                  setModalTitle={setModalTitle}
                  setModalImage={setModalImage}
                  setModalOpen={setModalOpen}
                ></Carousel>
              )
            })}
          </Container>
        </CarouselSection>
      </ScrollSection>
      <div>
        <CarouselModal
          modalOpen={modalOpen}
          modalTitle={modalTitle}
          modalContent={modalContent}
          modalImage={modalImage}
          modalName={modalName}
          setModalOpen={setModalOpen}
        />
      </div>
    </>
  )
}

export default Section
