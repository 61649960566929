import styled from 'styled-components'
import { mq } from '../../styles/vars/media-queries.style'
import { Heading2, Heading3 } from '../../styles/vars/textStyles.style'
import { Button } from '../Button/index.style'

export const TextSection = styled.div`
  padding-top: 10rem;

  ${mq.tabletP} {
    padding-top: 15rem;
  }

  ${mq.desk} {
    &:not(:last-of-type) {
      margin-bottom: 30rem;
    }
  }

  ${Heading3} {
    margin-bottom: 3rem;
  }

  ${Heading2} {
    margin-bottom: 2rem;

    ${mq.desk} {
      margin-bottom: 6rem;
    }
  }

  ${Button} {
    margin-top: 7rem;
  }

  ${({ single }) => {
    return single === true
      ? `
      ${mq.tabletL} {
      ${ContentLeft} {
        padding-right: 0;
      }
      ${ContentRight} {
        padding-left: 0;
      }}
      `
      : `
      ${mq.tabletL} {
      ${ContentLeft} {
    // padding-right: calc((100% / 16) * 5.5);
      }
      ${ContentRight} {
    //     padding-left: 0;
    // padding-left: calc((100% / 16) * 5.5);
      }}`
  }}
`

export const ContentLeft = styled.div`
  margin-bottom: 10.4rem;

  ${mq.tabletL} {
    margin-bottom: 20.2rem;
  }
`
export const ContentRight = styled.div`
  margin-bottom: 10.4rem;

  ${mq.tabletL} {
    margin-bottom: 20.2rem;
  }
`
