import React from 'react'
import { graphql } from 'gatsby'

// Components

// import Container from '../components/Container'
import CarouselSection from '../components/CarouselSection'
import DividingLine from '../components/DividingLine'
import Footer from '../components/Footer'
import Hero from '../components/Hero'
import Seo from '../components/Seo'
import TextRepeater from '../components/TextRepeater'
import ScrollSection from '../components/ScrollSection'

const AboutPage = ({ data }) => {
  const {
    heroTitle,
    rightOrLeftAligned,
    text,
    teams,
    seoTitle,
    seoMetaDescription,
  } = data.allContentfulPageAbout.edges[0].node

  return (
    <>
      <Seo
        title={seoTitle ? seoTitle : `About`}
        description={seoMetaDescription}
      />

      <ScrollSection>
        <>
          <Hero home={false} title={heroTitle} />
          <DividingLine />
        </>
      </ScrollSection>

      <TextRepeater repeaterContent={text} start={rightOrLeftAligned} />

      <ScrollSection>
        <DividingLine />
      </ScrollSection>

      <CarouselSection teams={teams} />

      <ScrollSection>
        <Footer />
      </ScrollSection>
    </>
  )
}

export default AboutPage

export const pageQuery = graphql`
  query AboutPageQuery {
    allContentfulPageAbout(filter: { slug: { eq: "about" } }) {
      edges {
        node {
          slug
          seoTitle
          seoMetaDescription {
            seoMetaDescription
          }
          heroTitle
          rightOrLeftAligned
          text {
            title
            copy {
              raw
            }
          }
          teams {
            carouselTitle
            carouselCopy {
              raw
            }
            carouselItems {
              name
              title
              bio {
                raw
              }
              image {
                file {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`
