import React, { useRef, useEffect, useContext } from 'react'

import { gsap } from 'gsap'
import { Draggable, InertiaPlugin } from 'gsap/all'

import { Heading2, TextBody } from '../../styles/vars/textStyles.style'

// Components
import AnimateFadeIn from '../animation/AnimateFadeIn'
import Button from '../Button'
import Grid from '../Grid/index'
import GridItem from '../GridItem/index'
import InViewSection from '../InViewSection/index'
import RichText from '../RichText'

// Context

import { StoreDispatch } from '../../Store'

import {
  Wrap,
  CarouselContainer,
  CarouselInner,
  CarouselItem,
  CarouselImage,
  // CarouselGradient,
  Section,
  CarouselWrapper,
} from './index.style'
import { TextBodyBold } from '../TextStyles'
import { useCallback } from 'react'

const Carousel = ({
  title,
  content,
  gallery,
  setModalContent,
  setModalTitle,
  setModalName,
  setModalOpen,
  setModalImage,
}) => {
  const dispatch = useContext(StoreDispatch)
  const teamMembersWrapperRef = useRef()
  const teamMembersRef = useRef()
  const teamRef = useRef()
  const sectionDelay = 0.5
  const length = gallery.length

  const openModal = useCallback(
    item => {
      setModalContent(item.bio)
      setModalTitle(item.title)
      setModalName(item.name)
      setModalImage(item.image)
      setModalOpen(true)
      dispatch({ type: 'CIRCLE_GROW' })
      dispatch({ type: 'HALF_SHOW_MODAL' })
      document.querySelector('body').classList.add('disable-scroll')
    },
    [
      setModalContent,
      setModalTitle,
      setModalName,
      setModalImage,
      setModalOpen,
      dispatch,
    ]
  )

  useEffect(() => {
    gsap.registerPlugin(Draggable, InertiaPlugin)

    Draggable.create(teamMembersRef.current, {
      type: 'x',
      edgeResistance: 0.9,
      inertia: true,
    })

    const applyDraggableBounds = () => {
      Draggable.get(teamMembersRef.current).applyBounds({
        minX:
          teamMembersWrapperRef.current.offsetWidth -
          teamMembersRef.current.offsetWidth,
        maxX: 0,
      })
    }

    applyDraggableBounds()

    window.addEventListener('resize', applyDraggableBounds)

    return () => {
      window.removeEventListener('resize', applyDraggableBounds)
    }
  }, [length])

  return (
    <Section ref={teamRef}>
      <Grid>
        <GridItem
          mobile={16}
          tabletL={3}
          tabletLStart={2}
          desk={3}
          deskStart={3}
        >
          <Wrap>
            <InViewSection>
              <AnimateFadeIn delay={sectionDelay}>
                <Heading2>{title}</Heading2>
                <RichText content={content} small />
              </AnimateFadeIn>
            </InViewSection>
          </Wrap>
        </GridItem>

        <GridItem mobile={16} tabletL={10} tabletLStart={7}>
          <InViewSection>
            <AnimateFadeIn delay={sectionDelay}>
              <CarouselContainer>
                <CarouselWrapper
                  ref={teamMembersWrapperRef}
                  onMouseEnter={() => dispatch({ type: 'SHOW_DRAG_CURSOR' })}
                  onMouseLeave={() => dispatch({ type: 'HIDE_DRAG_CURSOR' })}
                >
                  <CarouselInner ref={teamMembersRef}>
                    {gallery.map((item, key) => {
                      return (
                        <CarouselItem key={key}>
                          <CarouselImage src={item.image.file.url} alt="" />
                          <TextBody>{item.name}</TextBody>
                          <TextBodyBold>{item.title}</TextBodyBold>
                          <Button
                            type="open"
                            text={`Read Bio`}
                            event={() => {
                              openModal(item)
                            }}
                            onMouseEnter={() =>
                              dispatch({ type: 'HIDE_DRAG_CURSOR' })
                            }
                            onMouseLeave={() =>
                              dispatch({ type: 'SHOW_DRAG_CURSOR' })
                            }
                          >
                            Read Bio
                          </Button>
                        </CarouselItem>
                      )
                    })}
                  </CarouselInner>
                </CarouselWrapper>
              </CarouselContainer>
            </AnimateFadeIn>
          </InViewSection>
        </GridItem>
      </Grid>
    </Section>
  )
}

export default Carousel
